.text_block {
  z-index: 5;
}
.circle {
  z-index: 4;
}

@mixin style(
  $circleSize,
  $marginTop,
  $topOffset,
  $lineWidth,
  $lineHeight,
  $rightOffset,
  $leftOffset
) {
  .circle {
    margin-top: $marginTop;
    position: absolute;
    height: $circleSize;
    width: $circleSize;
    background: var(--circle);
    border-radius: 50%;
  }

  .text_block {
    width: max-content;
    .ctn {
      position: relative;

      h2 {
        position: relative;
        z-index: 9;
      }
    }
  }

  .underline {
    position: absolute;
    width: $lineWidth;
    height: $lineHeight;
    background: var(--accent);
    border-radius: 9px;
  }

  .top {
    .underline {
      top: $topOffset;
      right: $rightOffset;
    }
  }

  .bottom {
    .underline {
      top: $topOffset;
      left: $leftOffset;
    }
  }
}

@media screen and (min-width: 1301px) {
  @include style(300px, 10px, 73px, 500px, 13px, 80px, 190px);
  /*  $circleSize,
  $marginTop,
  $topOffset,
  $lineWidth,
  $lineHeight,
  $rightOffset,
$leftOffset
  */
}

@media screen and (max-width: 1300px) {
  .underline {
    border-radius: 7px;
  }
  @include style(275px, 10px, 60px, 425px, 11px, 62px, 157px);
}

@media screen and (max-width: 992px) {
  .underline {
    border-radius: 5px;
  }

  @include style(200px, 5px, 46px, 350px, 9px, 51px, 122px);
}

@media screen and (max-width: 576px) {
  .underline {
    border-radius: 3px;
  }

  @include style(150px, 3px, 30px, 200px, 5px, 33px, 76px);
}
