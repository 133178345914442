@use './mixins';

.list {
  width: 40%;
  margin: auto;
  margin-top: 7em;
  li {
    position: relative;
    width: 20%;
    padding-bottom: 20%;
    height: 0;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 20px 20px 10px rgba(var(--RGB_box-shadow), 0.1);
  }

  img {
    max-width: 100%;
  }
}

.img_wrapper {
  background-color: var(--empty-icon-bg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @include mixins.flex(column, true);
}

.top_row,
.bottom_row {
  @include mixins.flex(row, true);
  column-gap: 60px;
}

.top_row {
  margin-bottom: 50px;
}

.javascript {
  background: #f7df1e;
}

.typescript {
  background: #3178c6;
}

.react {
  background: #222;
}

@media screen and (max-width: 992px) {
  .list {
    width: 80%;
    margin-top: 6em;
  }
}

@media screen and (max-width: 576px) {
  .list {
    margin-top: 5em;
    li {
      width: 25%;
      padding-bottom: 25%;
      box-shadow: 0px 10px 20px 5px rgba(var(--RGB_box-shadow), 0.1);
    }
  }

  .top_row,
  .bottom_row {
    column-gap: 20px;
  }
}
