@use './mixins';

.main {
  height: 100vh;
  width: 100%;
  @include mixins.flex(column, true);
  row-gap: 60px;
  position: relative;
  background-image: var(--header-gradient);

  :global(.bg) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media screen and (max-width: 1300px) {
  .main {
    row-gap: 50px;
  }
}

@media screen and (max-width: 992px) {
  .main {
    row-gap: 40px;
  }
}

@media screen and (max-width: 576px) {
  .main {
    row-gap: 30px;
  }

  .underline {
    border-radius: 3px;
  }
}
