@use './functions';
@use 'sass:string';

@mixin getThemeColors($colors) {
  @each $color, $value in $colors {
    @if (string.index($color, 'filter') != null) {
      --#{$color}: #{$value};
    } @else {
      --#{$color}: #{$value};
      --RGB_#{$color}: #{functions.hexToRGB($value)};
    }
  }
}

@mixin flex($direction, $center) {
  display: flex;
  flex-direction: $direction;
  @if ($center) {
    justify-content: center;
    align-items: center;
  }
}

@function translate-media-condition($c) {
  $condMap: (
    'smallerThanDesktop': '(max-width: 992px)',
    'desktop': '(min-width: 993px)',
    'fullSizeDesktop': '(min-width: 1600px)',
    'smallerThanTablet': '(max-width: 768px)',
    'phone': '(max-width: 576px)'
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

@mixin sectionHeaderPadding() {
  @include media('smallerThanTablet') {
    padding: 0 8%;
  }

  @include media('smallerThanDesktop') {
    padding: 0 10%;
  }

  @include media('desktop') {
    padding: 0 18%;
  }

  @include media('fullSizeDesktop') {
    padding: 0 28%;
  }
}
