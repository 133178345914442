@use './mixins';

.main {
  @include mixins.flex(row, false);
  @include mixins.sectionHeaderPadding();
  justify-content: space-between;
  position: relative;
  z-index: 3;

  h2,
  h3,
  h5 {
    padding: 0;
    margin: 0;
  }

  h2 {
    font-family: 'Montserrat';
    color: var(--accent);
    font-size: 130px;
    margin-top: -10px;
  }

  span {
    position: absolute;
    bottom: 0;
  }
}

.title {
  h4 {
    color: var(--accent);
    margin-left: 4px;
    margin-bottom: -9px;
    width: max-content;
  }
}

@media screen and (max-width: 1300px) {
  .main {
    h2 {
      font-size: 103px;
      margin-top: -22px;
    }
  }
}

@media screen and (max-width: 992px) {
  .main {
    h2 {
      font-size: 76px;
      margin-top: -8px;
    }
  }

  .title {
    h4 {
      margin-left: 2px;
      margin-bottom: -6px;
    }
  }
}

@media screen and (max-width: 576px) {
  .main {
    h2 {
      font-size: 58px;
      margin-top: -8px;
    }
  }
}
