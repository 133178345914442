.remove {
  opacity: 0;
}

.flex-row {
  display: flex;
}

.main_section {
  padding-top: 13em;
}

.flat-btn {
  background-size: 200% 100%;
  background-image: linear-gradient(
    to right,
    var(--background) 50%,
    var(--accent) 50%
  );
  height: 42px;
  width: 120px;
  color: var(--accent);
  border: 2px solid var(--accent);
  border-radius: 5px;
  overflow: hidden;
  transition: all linear 0.1s;

  &:hover {
    background-position: -100% 0;
    color: var(--background);

    :global(.lds-ellipsis) div {
      background-color: var(--background);
    }
  }

  &:global(.loading) {
    user-select: none;
    cursor: default;
  }

  :global(.lds-ellipsis) {
    bottom: 21px;
    transform: scale(0.7);
  }
}

@media screen and (max-width: 992px) {
  .main_section {
    &:first-of-type {
      padding-top: 6em;
    }
  }
}

@media screen and (max-width: 576px) {
  .main_section {
    padding-top: 10em;
    &:first-of-type {
      padding-top: 6em;
    }
  }
}
