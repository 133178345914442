@use './functions';
@use 'sass:meta';
@use 'sass:math';

.main {
  svg {
    position: absolute;
    z-index: 3;
    max-width: 470px;
    color: var(--clouds);
    height: max-content;
  }

  .off_page {
    transform: translateX(-100vw);
  }

  $initTotal: 12;
  @for $i from 1 through $initTotal {
    .init:nth-child(#{$i}) {
      $rdm: random(100) - ($i * 5);
      $end: $rdm + 200;
      width: calc(random(32) * 1%);
      transform: translateX(#{$rdm}vw);
      top: calc(random(70) * 1%);
      animation: float#{$i} 100s linear forwards;

      @keyframes float#{$i} {
        to {
          transform: translateX(#{max($end, 100)}vw);
        }
      }
    }
  }
  $loopStart: $initTotal + 1;
  $total: $initTotal + 1 + 24;
  @for $j from $loopStart through $total {
    .off_page:nth-of-type(#{$j}) {
      width: calc(random(32) * 1%);
      top: calc(random(70) * 1%);
      animation: float#{$j} 100s linear infinite;
      animation-delay: #{($j - $loopStart) * $initTotal}s;

      @keyframes float#{$j} {
        to {
          transform: translateX(100vw);
        }
      }
    }
  }
}

.dark {
  filter: brightness(0.8);
}

.light {
  filter: brightness(1.15);
}

.mid_light {
  filter: brightness(1.07);
}
