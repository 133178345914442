@use './mixins';

.main {
  position: relative;
}

.bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 10%;
}

.fade {
  position: absolute;
  z-index: 2;
  display: block;

  width: 100%;
  height: 30em;

  top: 0;
  background-image: linear-gradient(
    to top,
    rgba(71, 71, 71, 0),
    var(--fade-color) 100%
  );
}
