@use './mixins';

.main {
  z-index: 1;

  header {
    align-items: flex-end;
    z-index: 99;
  }

  & > * {
    @include mixins.sectionHeaderPadding();

    flex-grow: 1;
  }
}

.content {
  display: flex;
  justify-content: space-between;
  @include mixins.sectionHeaderPadding();
  padding-top: 5em !important;
}

.text_wrapper {
  display: flex;
  justify-content: center;
  border-top: none;
  position: relative;

  p {
    margin-bottom: 20px;
  }
}

$offset: 3%;
.text_block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5em 5em;
  z-index: 5;
  border: 1px solid var(--main-text);
  position: relative;
  right: $offset;
  border-radius: 10px;
  aspect-ratio: 16/7;
}

.backdrop {
  position: absolute;
  top: calc(4 * $offset);
  left: $offset;
  right: -$offset;
  bottom: calc(-4 * $offset);
  z-index: 1;
  background: var(--backdrop);
  clip-path: polygon(
    calc(100% - 2 * $offset) 0%,
    100% 0%,
    100% 100%,
    0% 100%,
    0% calc(100% - 3.5 * $offset),
    calc(100% - 2 * $offset) calc(100% - 3.5 * $offset)
  );
  border-radius: 20px;
}

@include mixins.media('desktop') {
  .text_block {
    aspect-ratio: 16/5;
  }
}

@include mixins.media('smallerThanTablet') {
  .content {
    padding: 5em 15% 0 15%;
  }
  .text_block {
    padding: 2em;
  }
}

@include mixins.media('phone') {
  .backdrop {
    top: calc(2 * $offset);
    bottom: calc(-2 * $offset);
    z-index: 1;
    background: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%233a445d' fill-opacity='0.4'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    clip-path: polygon(
      calc(100% - 2 * $offset) 0%,
      100% 0%,
      100% 100%,
      0% 100%,
      0% calc(100% - 2 * $offset),
      calc(100% - 2 * $offset) calc(100% - 2 * $offset)
    );
    border-radius: 20px;
  }
}
