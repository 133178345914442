@use './mixins';

.main {
  margin: 7em 0;
  width: 60%;
  @include mixins.media('fullSizeDesktop') {
    width: 60%;
  }
  @include mixins.media('desktop') {
    width: 70%;
  }

  &:first-of-type {
    margin-top: 14em;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  margin-left: auto;

  &.reverse {
    margin-left: 0;
    .main_content {
      align-self: flex-start;
      padding-left: 10%;
      padding-right: 0;

      flex-direction: row-reverse;
    }
    header {
      flex-direction: row;
    }

    .stack {
      flex-direction: row;
    }

    .btn_ctn {
      align-self: flex-end;
      flex-direction: row-reverse;
      margin-left: 10px;
      margin-right: 0;

      .live {
        margin-left: 10px;
        margin-right: 0;
      }
    }

    .yellow_line {
      margin-left: 0;
      margin-right: 10%;
    }
    .red_line {
      margin-left: 0;
      margin-right: 20%;
    }

    .links {
      flex-direction: row-reverse;
    }
  }
}

.main_content {
  padding-right: 10%;
  align-self: flex-end;
  position: relative;
  display: flex;
  justify-content: space-between;

  header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.underline {
  height: 3px;
  border-radius: 5px;
}

.green_line {
  background-color: var(--decoration);
}
.yellow_line {
  background-color: var(--empty-icon-bg);
  margin-left: 10%;
}
.red_line {
  background-color: var(--accent);
  margin-left: 20%;
}

.text_col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  row-gap: 1.5em;
  @include mixins.media('desktop') {
    width: 65%;
  }

  header {
    width: 100%;
    display: flex;
  }

  :global(.flex-col) {
    width: max-content;
    display: flex;
    flex-direction: column;
    h4 {
      margin-bottom: 10px;
    }
  }

  p {
    text-align: justify;
    text-justify: inter-word;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    :global(.last) {
      flex: auto;
    }
  }

  :global(.flex-row) {
    position: relative;
    top: 10px;
    width: 100%;
    align-items: center;
  }

  .btn_ctn {
    z-index: 5;
    align-self: flex-end;
    display: flex;
    align-items: center;
    margin-right: 10px;

    a {
      display: block;
      margin: 0 10px;
      transition: 0.1s all ease-in;

      &.live {
        color: var(--accent);
        margin-left: 0;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.line {
  flex: 1;
  height: 0;
  border-top: 1px solid rgba(var(--RGB_main-text), 0.2);
}

.divider {
  user-select: none;
  color: var(--main-text);
}

.img_col {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  flex: 0 0 35%;
}

.img_wrapper {
  position: relative;
  height: 0;
  width: 75%;
  padding-bottom: 75%;

  img {
    border-radius: 10px;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.stack {
  font-size: 13px;

  margin: 0 2em;
  margin-bottom: 6px;

  display: flex;
  flex-direction: row-reverse;
  row-gap: 0.6em;
  column-gap: 1em;
  align-self: flex-end;
  flex-wrap: wrap-reverse;

  .tech {
    color: rgba(var(--RGB_main-text), 0.8);
  }
}

.reverse {
  flex-direction: row-reverse;

  .text_col {
    align-items: flex-start;
  }

  .img_col {
    align-items: flex-start;
  }
}

@media screen and (max-width: 1300px) {
  .img_col {
    width: 45%;

    h5 {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 992px) {
  .main {
    width: 100%;
    margin: 6em 0;
    &:first-of-type {
      margin-top: 11em;
    }
  }

  .main_content {
    padding: 0 10% !important;
  }

  .text_col {
    max-width: 100%;
  }

  .img_col {
    flex: 0 0 50%;

    h5 {
      font-size: 12px;
    }
  }

  .stack {
    width: max-content;
    margin: 0 auto;
    margin-top: 5em;
  }
}

@media screen and (max-width: 576px) {
  .main {
    margin: 5em 0;
    &:first-of-type {
      margin-top: 9em;
    }
  }
  .main_content {
    flex-direction: column-reverse !important;
    row-gap: 40px;
  }

  .text_col {
    align-items: flex-start;
  }

  .img_col {
    width: 100%;
  }

  .stack {
    font-size: 12px;
    margin-top: 4em;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    row-gap: 0.7em;
  }
}
