@use './mixins';

.main {
  textarea {
    resize: none;
  }

  form {
    margin: 80px 0;
    row-gap: 40px;
    @include mixins.flex(column, true);
    @include mixins.sectionHeaderPadding();
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: auto 200px;
  grid-auto-flow: dense;
  row-gap: 20px;

  label {
    grid-column: 1;
  }

  textarea {
    height: 100%;
  }
}

.input_wrapper {
  grid-column: 2;
}

.input_wrapper {
  input,
  textarea {
    width: 100%;
  }
}

.error_msg {
  color: #ff6347;
  margin-top: 5px;
  font-size: 14px;
}

.send_status {
  @include mixins.flex(row, true);
  column-gap: 10px;

  &.success img {
    filter: invert(54%) sepia(55%) saturate(601%) hue-rotate(68deg)
      brightness(101%) contrast(78%);
  }

  &.error img {
    filter: invert(55%) sepia(61%) saturate(3583%) hue-rotate(332deg)
      brightness(101%) contrast(101%);
  }
}

@media screen and (max-width: 568px) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 200px;
    grid-auto-flow: column dense;
    row-gap: 10px;
  }

  .input_wrapper {
    grid-column: 1;
  }

  $elements: 4;
  @for $i from 1 to $elements {
    label:nth-child(#{$i + 1}) {
      grid-row: $i +
        1 +
        $i; //second $i represents how many labels behind current label, need this here so you know many label+inputs to skip over
    }
  }
}
