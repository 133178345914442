.main {
  position: fixed;
  display: flex;
  justify-content: space-between;
  left: 0;
  right: 0;
  padding: 10px 10%;
  background-color: var(--background);
  z-index: 98;
  transition: transform 0.1s ease-in;

  &.scroll {
    box-shadow: 0 5px 10px 5px rgba(var(--RGB_box-shadow), 0.1);
  }

  &.hide {
    transform: translateY(-100%);
  }
}

.right,
.left {
  display: flex;
  align-items: center;
  color: var(--main-text);
  column-gap: 2em;
}

.takeoff_btn {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}

.filter_applier {
  filter: var(--accent-filter);
}
.rocket_main {
  position: relative;
  z-index: 10;
  mask: linear-gradient(
      -60deg,
      rgb(150, 150, 150) 30%,
      rgba(150, 150, 150, 0.3),
      rgb(150, 150, 150) 70%
    )
    right/400% 100%;
  animation: shimmer 2s infinite;
}
@keyframes shimmer {
  100% {
    mask-position: left;
  }
}

@mixin elSize($icon_size) {
  .theme_picker {
    input {
      height: calc($icon_size + 5px);
      width: calc(2 * ($icon_size + 5px));
    }

    input:before {
      height: calc($icon_size + 7px);
      width: calc($icon_size + 7px);
    }

    input:checked:before {
      transform: translateX(calc($icon_size + 5px));
    }
  }

  .icon_wrapper {
    svg {
      height: $icon_size;
    }
  }
}

.theme_picker {
  display: flex;

  input {
    position: relative;
    outline: 0;
    border: none;
    border-radius: 50px;
    background-color: #f1f3ee;
    appearance: none;
    display: block;
    cursor: pointer;
    z-index: 99;
  }

  input:before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='%23EB8258'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z'/%3E%3C/svg%3E");
    background-color: #e5e6e4;
    font-weight: 900;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 40px;
    outline: none;
    cursor: pointer;
    transition: transform 0.2s ease-in;
  }

  input:checked:before {
    background-color: #2b303a;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' fill='%23F5FF90'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z'/%3E%3C/svg%3E");
  }

  input:checked {
    background-color: #8c93a8;
  }
}

.btn_ctn {
  display: flex;
  align-items: center;

  svg {
    filter: var(--main-text-filter);
  }
}

.icon_wrapper {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1301px) {
  @include elSize(30px);
}

@media screen and (max-width: 1300px) {
  @include elSize(25px);

  .theme_picker {
    input:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' fill='%23EB8258'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z'/%3E%3C/svg%3E");
    }

    input:checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='20' height='20' fill='%23F5FF90'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z'/%3E%3C/svg%3E");
    }
  }
}

@media screen and (max-width: 992px) {
  @include elSize(22px);

  .theme_picker {
    input:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' fill='%23EB8258'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z'/%3E%3C/svg%3E");
    }

    input:checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='18' height='18' fill='%23F5FF90'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z'/%3E%3C/svg%3E");
    }
  }
}

@media screen and (max-width: 768px) {
  @include elSize(18px);

  .theme_picker {
    input:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='15' height='15' fill='%23EB8258'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z'/%3E%3C/svg%3E");
    }

    input:checked:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='15' height='15' fill='%23F5FF90'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z'/%3E%3C/svg%3E");
    }
  }
}
