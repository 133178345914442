.tooltip {
  z-index: 99;
  position: absolute;
}

.tooltip-wrapper {
  position: relative;
  background-color: var(--tooltip-bg);
  padding: 0.5em 1em;
  user-select: none;
  border-radius: 4px;
}
.tooltip-wrapper::before {
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: var(--tooltip-bg);
  z-index: 1;
}
.tooltip-wrapper.right:before {
  bottom: 50%;
  left: 0%;
  margin-bottom: -5px;
  margin-left: -5px;
  transform: rotate(45deg);
}
.tooltip-wrapper.top:before {
  bottom: 0%;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  transform: rotate(45deg);
}
.tooltip-wrapper.bottom:before {
  top: 0%;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  transform: rotate(45deg);
}

.tooltip-wrapper .text-wrapper {
  font-size: 16px;
  color: var(--main-text);
}
